<template>
    <div style="width: 100%;">
<!--        <div style="width: 100%; padding-top: 20px; padding-bottom: 20px; background: #eee;" >-->
            <news-list-view
                style="display: none;"
                v-if="newsItems.length > 0"
                :items=newsItems></news-list-view>
<!--        </div>-->
        <div class="container-fluid bg_bottom" style="padding-top: 30px;">
            <footer class="row">
                <div class="social_links_wrapper " style="display: none;">

                    <a href="" class="social_link fa_icon_md">
                        <i class="fas fa-plus"/>
                    </a>
                    <a href="" class="social_link fa_icon_md">
                        <i class="fas fa-minus"/>
                    </a>
                </div>
                <div class="footer_menu_wrapper bg_bottom">
                    <div class="menu-footer-menu-container">
                        <ul id="menu-footer-menu" class="menu">
                            <li class="menu-item" style="display: none;">
                                sustainability
                            </li>
                            <li class="menu-item" style="display: none;">
                                <router-link to="/myPage">my account</router-link>
                            </li>
<!--                            <li class="menu-item">-->
<!--                                <router-link to="/returns">returns</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item" style="display: none;">-->
<!--                                payment-->
<!--                            </li>-->
                            <li class="menu-item">
                                <router-link to="/privacy-policy">privacy policy</router-link>
                            </li>
                            <li class="menu-item">
                                <router-link to="/contact">contact us</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer_legal_text_wrapper">
                    <p class="footer_legal_text text-center">
                        <span>©2023 Kokoro Trading Ltd<br/>4 Lyon Road, Hersham, Walton-on-Thames, Surrey, KT12 3PU, United Kingdom</span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import {getMetas} from "@/api/metas";
import NewsListView from "@/components/NewsListView.vue";
export default {
    name: "Bottom",
    data() {
        return {
            newsItems:[],
        }
    },
    components:{
      NewsListView,
    },
    created() {
    },
    mounted() {
        this.loadNews();
    },
    methods: {
        loadNews: function() {
            let self = this;
            getMetas('news')
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.newsItems = data.item;
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        }
    }
}
</script>

<style scoped>
.bg_bottom {
    background-color: #333334;
}
footer .social_links_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 1rem 2rem;
}
.fa_icon_md {
    font-size: 2rem !important;
    color:white;
}
.social_links_wrapper .social_link {
    padding: 0 0.5rem;
    margin: 0 1.5rem;
}
footer .footer_menu_wrapper {
    width: 100%;
    padding: 0 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #ededed;
}
footer .footer_menu_wrapper #menu-footer-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
}
footer .footer_menu_wrapper #menu-footer-menu li {
    margin: 0 0.5rem;
    padding: 0 1rem;
    color: white;
    display: list-item;
    text-transform: uppercase;
}
footer .footer_menu_wrapper #menu-footer-menu li a{
    color: white;
}
footer .footer_legal_text_wrapper {
    width: 100%;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
}
footer .footer_legal_text_wrapper .footer_legal_text {
    font-weight: 300;
    color:white;
}
</style>
