// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Auth from "@/store/auth";


import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors'
});




/**
 * Firebase  설정
 */
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyDqTCAFZyJjP_F4UnkVJ5pmWQyljVD43_U",
    authDomain: "kokoro-294123.firebaseapp.com",
    databaseURL: "https://kokoro-294123.firebaseio.com",
    projectId: "kokoro-294123",
    storageBucket: "kokoro-294123.appspot.com",
    messagingSenderId: "956950391804",
    appId: "1:956950391804:web:6890b41a778a1b7df96079",
    measurementId: "G-1KD9GX7YYE"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const EventBus = new Vue();
window.auth = new Auth();
/**
 * End of Firebase 설정
 */

/**
 * Vue Datetime
 */
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

/**
 * momentjs
 */
import moment from "moment";
import VueMomentJS from "vue-momentjs";

Vue.use(VueMomentJS, moment);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
