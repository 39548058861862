<template>
    <div style="width: 100%; background: white; padding-top: 30px; padding-bottom: 30px;">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Delivery Policy</h4>

                    <p>Delivery Policy to be decided</p>

                    <div class="tnc-section-title">Collections- Covid 19 period</div>

                    <p>Collections are permitted, however customers are not allowed to enter the premises. The process to follow is as below</p>
                    <ul>
                        <li>Place an order by email(sales@kokorotrading.com) or phone(01932703309), then ask for a collection if you want.
                            When you arrive for collection, give us a call once again.</li>
                        <li>
                            Once Order is accepted and paid, we will send the order down to warehouse for picking
                        </li>
                        <li>
                            Once items are picked, the goods will be handed over to customer for takeaway
                        </li>
                        <li>
                            Please note that customers will have to wait until order is picked- it is not an instant service so please be patient.
                        </li>
                    </ul>

                    <div class="tnc-section-title">Deliveries (UK)</div>
                    <p>
                        During Covid 19 situation delivery drivers will not be requiring signatures for proof. Delivery is to the front door only. We appreciate your understanding in this unprecedented time.
                    </p>
                    <p>
                        “PLEASE MAKE SURE THE CORRECT DELIVERY ADDRESS IS GIVEN TO AVOID ANY DELAYS IN RECEIVING YOUR GOODS.”
                    </p>
                    <p>
                        Orders over £130 ex vat being delivered to mainland UK addresses (not including Scottish Highland and certain rural addresses) will receive Free Shipping. Delivery service is between 1-3 working days although we do send goods out aiming for a next day delivery service.
                    </p>

                    <div class="tnc-section-title">Returning Goods</div>
                    <p>
                        Returns must be authorised by head office. Goods must be returned as they were sold, unopened and resell-able. Once we receive the goods back a refund will be given. If we had to collect the goods then this charge would be taken off the refund. If the goods delivered were incorrect and the fault was ours, we will arrange the collection and refund in full when the goods are back with us.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Delivery"
}
</script>

<style scoped>
.tnc-section-title {
    color: green;
    font-size: 1.2rem;
    font-weight: bold;
}
</style>
