<template>
    <div style="width: 100%">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">Business Type</div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row" style="padding-bottom: 50px;">
                <div class="col-6 col-md-4" v-for="c in categories" v-bind:key="'category-item-'+ c.id">
                    <div class="category-card card" @click="onCategoryClicked(c)">
                        <div class="card-header">

                            <div class="category-card-item-image" v-if="c.mainImage !== null && c.mainImage.length > 0" v-bind:style="{backgroundImage: 'url(' + c.mainImage + ')'}">

                            </div>
                        </div>
                        <div class="card-body">
                            {{c.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getMetas} from "@/api/metas";

export default {
    name: "BusinessType",
    data() {
        return{
            categories:[],
        }
    },
    created() {
    },
    mounted() {
        this.loadCategoryList();
    },
    methods: {
        loadCategoryList: function() {
            let self = this;
            getMetas('business')
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.categories = data.item;
                    } else {

                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        onCategoryClicked: function(c) {
            this.$router.push('/business-type/' + c.id);
        }
    }
}
</script>

<style scoped>
.page-title {
    padding-top:20px;
    padding-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
.category-card {
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 5px gray;
}
.category-card .card-header {
    padding: 0;
}
.category-card-item-image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.category-card .card-body {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
}
</style>
