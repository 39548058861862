import EventBus from "./event-bus";
import axios from "axios";
let header = {
    headers: {

        appId:'kokoromall',
        appKey:'asjdfsd0fsdfopksdlf',
        "Content-Type": "application/json",
    }
}
export function signUpCustomer(user) {
    return new Promise(function (resolve, reject) {
        axios.post(process.env.VUE_APP_API_BASE_URL + 'user/customer/signUp/', JSON.stringify(user), header)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function userSignIn(email, password) {
    return new Promise(function (resolve, reject) {
        let url = process.env.VUE_APP_API_BASE_URL + 'user/signIn?email=' + email + '&password=' + password;
        axios.post(url)
            .then(function (response) {
                let data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if(error.response != undefined && error.response.data != undefined && error.response.data.status != undefined && error.response.data.status == 403) {
                    EventBus.$emit("sessionExpired");
                }
                reject(error);
            })
    })
}
export function getUserByToken() {
    return new Promise(function(resolve, reject) {
        console.log("getUserBytToken " + localStorage.getItem("JSTOKEN") + " "  + process.env.VUE_APP_API_BASE_URL);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'user/me', {
            headers:{
                "X-AUTH-TOKEN": localStorage.getItem("JSTOKEN")
            }
        }).then(function(response) {
            let data = response['data'];
            console.log(data);
            if(data != null && data != "") {
                resolve(data);
            } else {
                EventBus.$emit("sessionExpired");
                // reject();
            }
        })
            .catch(error=>{
                if(error.response != undefined && error.response.data != undefined && error.response.data.status != undefined && error.response.data.status == 403) {
                    EventBus.$emit("sessionExpired");
                }
                reject(error)
            });
    })
}
