import EventBus from "./event-bus";
export function showDialog(message = '', title = '') {
    let params = {
        title:title,
        text:message
    }
    EventBus.$emit("showDialog", params);
}
export function timeToLocaleDateShort(time) {
    let date = new Date(time);
    return date.toLocaleDateString('en-GB', {
        month: 'long', day: 'numeric',  year: 'numeric'
    });
}
