<template>
    <div id="app">
        <div class="header-view-wrapper">
            <Header></Header>
        </div>
        <div class="router-view-wrapper">
            <router-view></router-view>
            <Bottom/>
        </div>

    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Bottom from "@/components/Bottom.vue";

export default {
    name: 'App',
    components: {
        Bottom,
        Header

    },

};
</script>

<style scoped>

/*
 * vw, vh:
 * 다른 css 값(부모객체 등)에 간섭받지 않고,
 * 뷰포트의 사이즈를 사용 하능한 단위
 * (viewport's width / height)
 */
#app {
    width: 100%;
    height: 100%;
}
.header-view-wrapper {
    width: 100%;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 2;
}
.router-view-wrapper {
    width: 100%;
    left: 0;
    top: 0;
    /*padding-left: 200px;*/
    background-color: #ECF0F1;
    z-index: 1;
}
@media only screen and (max-width: 1100px) {
    .header-view-wrapper {
        height: 80px;
    }
}

@media only screen and (max-width: 860px) {
    .header-view-wrapper {
        height: 130px;
    }
}

</style>
