import EventBus from "./event-bus";

const axios = require('axios');

export function getFrontPage() {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/page/menus')
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}

export function getHomePage() {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/page/home')
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}

export function getCategoryPage(categoryId) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/page/category/' + categoryId)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function getBusinessPage(businessId) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/page/business/' + businessId)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function getProductPage(productId) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/page/product/' + productId)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function searchComProduct(word) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/search?word=' + word)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
