<template>

    <div style="width: 100%;">
        <div class="slideshow-container">
            <template v-for="(image, iidx) in imageItems">
                <div class="my-slide slider-fade" v-bind:class="{'show' : iidx !== currentSliderIndex}" v-bind:key="'image-item-slider-'+image.id">
                    <img :src="image.mainImage"/>

                </div>
            </template>
            <div class="slider-dot-container">
                <template v-for="(image, iidx) in imageItems">
                    <span v-bind:key="'slider-dot-' + iidx" class="dot" v-bind:class="{'active' : iidx === currentSliderIndex}" @click="moveSlide(iidx)"></span>
                </template>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12 text-center section-title">
                    Categories
                </div>
            </div>
            <div class="row" style="padding-bottom: 50px;">
                <div class="col-6 col-md-4" v-for="c in categories" v-bind:key="'category-item-'+ c.id">
                    <div class="category-card card" @click="onCategoryClicked(c)">
                        <div class="card-header">

                            <div class="category-card-item-image" v-if="c.mainImage !== null && c.mainImage.length > 0" v-bind:style="{backgroundImage: 'url(' + c.mainImage + ')'}">

                            </div>
                        </div>
                        <div class="card-body category-title">
                            {{c.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Slider, SliderItem} from "vue-easy-slider";
import {getHomePage} from "@/api/pages";

export default {
    name: "Home",
    data() {
        return {
            imageItems:[],
            categories:[],
            currentSliderIndex:0,
            pageLeave: false,
        }
    },
    components: {
        // Slider,
        // SliderItem,
    },
    created() {
    },
    mounted() {
        this.loadPage();
    },
    beforeDestroy() {
        this.$data.pageLeave = true;
    },
    methods: {
        loadPage:function() {
            let self = this;
            getHomePage()
                .then(function(data) {
                    if(data.success === true) {
                        if(data.item.imageItems !== null && data.item.imageItems.length > 0) {
                            self.$data.imageItems = data.item.imageItems;
                        }
                        if(data.item.categories !== null && data.item.categories.length > 0) {

                            data.item.categories.sort(function(a, b){
                                return a.pos - b.pos;
                            })
                            self.$data.categories = data.item.categories;
                            console.log("After sorting")
                            console.log(data.item.categories);

                        }
                        self.changeSlider();
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        onCategoryClicked: function(c) {
            this.$router.push('/product-category/' + c.id);
        },
        moveSlide: function(index) {
            this.$data.currentSliderIndex = index;
        },
        changeSlider: function() {
            if(this.$data.pageLeave === true) {
                return;
            }
            let self = this;
            setTimeout(function (){

                if(self.$data.currentSliderIndex+1 >= self.$data.imageItems.length) {
                    self.$data.currentSliderIndex = 0;
                } else {
                    self.$data.currentSliderIndex++;
                }
                console.log("gallery index ", self.$data.currentSliderIndex);
                self.changeSlider();
            }, 10000);
        }
    }
}
</script>

<style scoped>

.slider-container {
    width: 100%;
    padding-bottom: 26.15%;
    //height: 500px;
}
.main-image-slider-item {
    width: 100%;
    //display: block;
    //height: 100%;
    padding-top: 26%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.main-image-slider-cover {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}
.main-image-slider-item .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    padding:20px;
    color:white;
    font-weight: bold;;
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase
}

.category-card {
    margin-bottom: 50px;
    cursor: pointer;
    transition: all 0.1s ease-in;
}
.category-card:hover {
    transform: translateY(-10px);
}
.category-card .card-header {
    padding: 0;
}
.category-card-item-image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: white;
}
.category-card .card-body {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
}

.my-slide {display: none}
.my-slide img {vertical-align: middle; width: 100%;}

.my-slide.show {
    display: block;
}
/* Slideshow container */
.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
}
/* Fading animation */
.slider-fade {
    animation-name: slider-fade;
    animation-duration: 0.5s;
}

@keyframes slider-fade {
    from {opacity: .4}
    to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .prev, .next,.text {font-size: 11px}
}
.section-title {
    line-height: 70px;
    font-size: 70px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-transform: uppercase;
}

.card-body.category-title {
    line-height: 60px;
    font-size: 30px;
    text-transform: uppercase;
}
@media only screen and (max-width: 430px) {
    .section-title {
        font-size: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .card-body.category-title {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 5px;
    }
}
.slider-dot-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    box-shadow: none;
}
.dot.active {
    background-color: #fff;
    box-shadow: 5px 5px 15px gray;
}
</style>
