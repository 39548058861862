<template>
    <div style="width: 100%">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Returns / Collections</h4>
                    <p>Returns must be authorized by head office. Goods must be returned as they were sold, unopened and resell-able. Once we receive the goods back a refund will be given. If we had to collect the goods then this charge will be taken off the refund. A collection/handling charge is 15% of the value of the goods being returned/collected.</p>

                    <p>
                        If the goods delivered were incorrect and the error was ours, we will arrange the collection and refund in full when the goods are back with us.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Return",
    created() {
        window.scrollTo(0, 0)
    },
    mounted() {
    },
}
</script>

<style scoped>

</style>
