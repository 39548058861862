<template>

    <div style="width: 100%; padding-top: 50px; padding-bottom: 50px;">
        <div class="login-container">
            <div class="container inner-login-container">
                <div class="row">
                    <div class="col-12">

                        <input class="form-control" type="email" placeholder="EMAIL" v-model="email"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">

                        <input class="form-control" type="password" placeholder="Password" v-model="password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="form-control" type="password" placeholder="Password Confirm" v-model="passwordConfirm"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-primary form-control" @click="submit">SIGN UP</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Already have account? Click <a href="/signIn">Sing In</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {signUpCustomer} from "@/api/users";
import {showDialog} from "@/api/common";

export default {
    name: "SignUp",
    data() {
        return {
            email:'',
            password:'',
            passwordConfirm:'',
        }
    },
    props: {
      onClose: {
          type:Function,
          default: null,
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        validateEmail: function() {
            if(this.$data.email === null || this.$data.email.length === 0) {
                return false;
            }

            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(this.$data.email.match(validRegex)) {
                return true;
            }
            return false;
        },
        validatePassword: function() {
            if(this.$data.password === null || this.$data.password.length < 6) {
                return false;
            }
            if(this.$data.passwordConfirm === null || this.$data.passwordConfirm.length < 6) {
                return false;
            }
            if(this.$data.password !== this.$data.passwordConfirm) {
                return false;
            }
        },
        submit: function() {
            if(this.validateEmail() === false) {
                showDialog("Incorrect Email");
                return false;
            }
            if(this.validatePassword() === false) {
                showDialog("Passwords are not matching");
                return false;
            }

            let user = {
                email: this.$data.email,
                password:this.$data.password,
            };

            signUpCustomer(user)
                .then(function(data) {
                    if(data.success === true) {
                        showDialog(data.message);
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                    showDialog("Something went wrong", "OOPS!");
                })
        }
    }
}
</script>

<style scoped>
.login-container {
    width: 500px;
    max-width: 90%;
    margin: auto;
    padding: 50px 20px 50px 20px;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: white;
}
.inner-login-container .row {
    padding-top: 20px;
    text-align: center;
}
.inner-login-container input {
    text-align: center;
}
</style>
