import EventBus from "./event-bus";
import axios from "axios";

export function getMetas(type) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/metas/' + type)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
