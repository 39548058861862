<template>
    <div style="width: 100%; padding-bottom: 30px;">
        <div class="container">
            <div class="row">
                <div class="col-6 page-title">
                    My Account
                </div>
                <div class="col-6 page-title text-right">
                    <span style="font-weight: normal;" @click="logOut">Log out</span>
                </div>
            </div>
        </div>
        <div class="container" style="padding-top: 20px;">
            <div style="width: 100%; display: flex;">
                <div style="width: 150px;">
                    <div class="menu-item" v-bind:class="{'active': mode === 'account'}" @click="changeMode('account')">My Details</div>
                    <div class="menu-item" v-bind:class="{'active': mode === 'pending'}" @click="changeMode('pending')">Current Order</div>
                    <div class="menu-item" v-bind:class="{'active': mode === 'history'}" @click="changeMode('history')">Order History</div>
                </div>
                <div style="width: calc(100% - 150px);">

                    <div class="container" v-if="mode === 'account'">
                        <div class="row">
                            <div class="col-12">
                                <div class="table-container">
                                    <table class="account-table">
                                        <tr>
                                            <td>
                                                Name
                                            </td>
                                            <td>
                                                {{user.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Email
                                            </td>
                                            <td>
                                                {{user.email}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Contact
                                            </td>
                                            <td>
                                                {{user.contact}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="container" v-if="mode === 'pending'">
                    </div>
                    <div class="container" v-if="mode === 'history'">

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>

import {getUserByToken} from "@/api/users";
import EventBus from "@/api/event-bus";

export default {
    name:'MyPage',
    data() {
        return {
            user: false,
            name:'',
            address:'',
            postCode:'',
            contact:'',
            orders:[],
            newPassword:'',
            newPasswordConfirm:'',
            mode:'account',
        }
    },
    created() {
        window.scrollTo(0, 0);
    },
    mounted() {
        this.loadPage();
    },
    methods: {
        loadPage: function() {
            let self = this;
            getUserByToken()
                .then(function(data) {
                    self.$data.user = data;
                    self.$data.name = data.name;
                    self.$data.address = data.address;
                    self.$data.contact = data.contact;
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        logOut: function() {
            EventBus.$emit("logout", true);
        },
        changeMode: function(newMode) {
          if(newMode === this.$data.mode) {
              return;
          }

          if(this.$data.mode === 'account') {
              this.loadPage();
          } else if(this.$data.mode === 'history') {
              this.getOrderList();
          }
        },
        getOrderList: function() {

        }
    }
}
</script>
<style scoped>
.page-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;

}
.account-table td {
    padding: 10px;
}

.table-container {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: white;
}

.menu-item {
    width: 100%;
    padding: 10px;
    text-align: center;
    border: 1px solid #c4c4c4;
    background-color: white;
}
.menu-item.active {
    background-color: #28ae9d;
    color: white;
}
</style>
