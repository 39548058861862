import Vue from 'vue'
import Router from 'vue-router'
import VModal from 'vue-js-modal'
import Home from "@/components/Home.vue";
import Categories from "@/components/Categories.vue";
import ProductCategory from "@/components/ProductCategory.vue";
import Product from "@/components/Product.vue";
import PrivacyPolicy from "@/components/privacyPolicy.vue";
import Basket from "@/components/Basket.vue";
import BusinessType from "@/components/BusinessType.vue";
import ProductBusinessType from "@/components/ProductBusinessType.vue";
import Return from "@/components/Return.vue";
import Delivery from "@/components/Delivery.vue";
import ContactUs from "@/components/ContactUs.vue";
import SignIn from "@/components/SignIn.vue";
import SignUp from "@/components/SignUp.vue";
import AboutUs from "@/components/AboutUs.vue";
import Order from "@/components/Order.vue";
import MyPage from "@/components/MyPage.vue";
import SearchProducts from "@/components/SearchProducts.vue";

Vue.use(Router);
Vue.use(VModal, { dialog: true, width:'90%' });

Vue.prototype.logged  = false;

export default new Router({
    mode: 'history',
    routes:[
        {
            path:'/',
            name:'Home',
            component:Home
        },
        {
            path:'/categories',
            name:'Categories',
            component:Categories
        },
        {
            path:'/product-category/:category',
            name:'Product-Category',
            component:ProductCategory
        },
        {
            path: '/business-types',
            name:'BusinessTypes',
            component: BusinessType,
        },
        {
            path:'/business-type/:business',
            name:'Business-Type',
            component:ProductBusinessType
        },
        {
            path:'/shop/product/:id',
            name:'Product',
            component:Product,
        },
        {
            path:'/privacy-policy',
            name:'PrivacyPolicy',
            component: PrivacyPolicy
        },
        {
            path:'/basket',
            name:'Basket',
            component: Basket
        },
        {
            path:'/returns',
            name: 'Return',
            component: Return
        },
        {
            path: '/delivery',
            name: 'Delivery',
            component: Delivery,
        },
        {
            path: '/contact',
            name: 'Contact',
            component: ContactUs
        },
        {
            path: '/samples',
            name: 'Sample',
            component: ContactUs
        },
        {
            path: '/signIn',
            name: 'SignIn',
            component: SignIn
        },
        {
            path: '/signUp',
            name: 'SignUp',
            component: SignUp
        },
        {
            path: '/aboutUs',
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: '/myorder',
            name: 'MyOrder',
            component: Order
        },
        {
            path: '/mypage',
            name: 'My Page',
            component: MyPage
        },
        {
            path: '/search',
            name: 'Search',
            component: SearchProducts
        }
    ]
})
