export default class Auth {
    constructor() {
        this.currentUser = null;
        this.groups = [];
        let self = this;
        // if(localStorage.getItem("auth") != undefined) {
        //   getUserByToken().then(function(user){
        //     self.currentUser = user;
        //     console.log("AUTH logged on from constructor");
        //     EventBus.$emit("logon", true);
        //   });
        // }
    }

    setUser() {

        console.log("setUser");
        // if (localStorage.getItem("auth") != undefined) {
        //   let self = this;
        //   getUserByToken().then(function (user) {
        //     self.currentUser = user;
        //     console.log("AUTH logged on from setUser");
        //     console.log(self.currentUser);
        //     EventBus.$emit("logon", true);
        //     //self.getOtherUserList();
        //   });
        // }
    }
}
