import EventBus from "./event-bus";
import axios from "axios";
let header = {
    headers: {

        appId:'kokoromall',
        appKey:'asjdfsd0fsdfopksdlf',
        "Content-Type": "application/json",
    }
}

export function getAddressListByPostCode(searchWord) {
    return new Promise(function (resolve, reject) {
        EventBus.$emit("showLoading", true, "Getting menu list");
        console.log("getMerchant Menus ")
        axios.get("https://api.getaddress.io/find/" +  searchWord + "?expand=true&api-key=SWmswC2VIEWbr3V6KN6aRA29638")
            .then(function (response) {
                EventBus.$emit("showLoading", false);
                resolve(response);
            })
            .catch(error => {
                EventBus.$emit("showLoading", false);
                reject(error)
            });
    })
}
export function submitOrder(order) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.post(process.env.VUE_APP_API_BASE_URL + 'public/shop/order', JSON.stringify(order), header)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function retrieveOrder(id) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.get(process.env.VUE_APP_API_BASE_URL + 'public/shop/order/' + id, header)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
export function createStripeSession(order) {
    return new Promise(function (resolve, reject) {
        console.log("getMerchant Menus ")
        axios.post(process.env.VUE_APP_API_BASE_URL + 'public/shop/stripe/session', JSON.stringify(order), header)
            .then(function (response) {
                let data = response['data'];
                console.log(data);
                resolve(data);
            })
            .catch(error => {
                reject(error)
            });
    })
}
