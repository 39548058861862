<template>
    <div style="width: 100%; background: white; padding-top: 30px; padding-bottom: 30px;">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="tnc-section-title">CONTACT / SAMPLES</div>
                    <p>
                        Current opening and closing times are 9.00am to 5.00pm Monday to Friday.
                        Currently our office is closed to the general public. Collection can be made by calling our sales office on 01932 703 309 to place orders and have goods collected. Please allow 30  minutes from time of order to goods being picked.
                    </p>
                    <p>
                        <span style="font-weight: bold;">Address:</span>
                        <br/>
                        KOKORO TRADING LTD
                        <br/>
                        3 Lyon Road, Hersham
                        <br/>
                        Walton-upon-thames, Surrey
                        <br/>
                        United Kingdom
                        <br/>
                        <a href="tel:01932703309">Tel: 01932 703 309</a>
                        <br/>
                        <a href="mailto:sales@kokorotrading.com">Email: sales@kokorotrading.com</a>

                    </p>
                    <p>
                        To order samples please send an email to the address below with your business name, address and a description of the items you wish to have samples of. A charge of £9.00 ex vat will need to be paid in order to send the samples out on a 2-3 day service. Please note we only send 1-2 pieces of each sample that you are interested in.
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ContactUs"
}
</script>

<style scoped>
.tnc-section-title {
    color: green;
    font-size: 1.2rem;
    font-weight: bold;
}
</style>
