<template>
    <div style="width: 100%; background: white; padding-top: 30px; padding-bottom: 30px;">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>
                        Kokoro Trading Ltd (KTL) is
                    </h4>

                    <p>
                        Our prices are competitive and we supply into the varied food & beverage outlets. If you’re looking for better packaging for your business then we can help you. Supplying cafes, deli’s, street food vendors, contract caterers and many other types of food related businesses, KTL can be your one stop shop for all disposable packaging needs.
                    </p>
                    <p>
                        With a focus on quality products, affordable prices, flexible volumes and outstanding customer service, KTL is well placed to service the needs and requirements of the restaurants, cafes, takeaway shops, wholesale and private companies.

                    </p>
                    <p>
                        Send email now on sales@kokorotrading.com or browse our online shop
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUs"
}
</script>

<style scoped>

</style>
