<template>

    <div style="width: 100%; padding-top: 30px;">
        <div class="container" v-if="item !== false">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="product-image-container">
                        <img :src="selectedImage" style="width: 90%; margin: auto;"/>
<!--                        <div class="image" v-if="selectedImage !== false" v-bind:style="{backgroundImage: 'url(' + selectedImage + ')'}">-->

<!--                        </div>-->
                    </div>
                    <div class="product-image-list-container">
                        <div v-if="item.mainImage !== null && item.mainImage.length > 0"
                             @click="selectedImage = item.mainImage"
                             class="image-item" v-bind:key="'image-item-index-0'" v-bind:style="{backgroundImage: 'url(' + item.mainImage + ')'}"/>
                        <template v-for="(image, index) in item.images">
                            <div class="image-item" v-bind:key="'image-item-index-' + (index+1)"
                                 @click="selectedImage = image"
                                 v-bind:style="{backgroundImage: 'url(' + image + ')'}"/>
                        </template>
                    </div>
                </div>
                <div class="col-12 col-md-6" style="padding-bottom: 30px;">
                    <div class="product-description-container">
                        <div class="title">
                            <h1>{{item.title}}</h1>
                        </div>
                        <div class="info">
<!--                            Price: £{{(item.net/100.0).toFixed(2)}}-->
<!--                            <br/>-->
                            <h4>
                            Price: £{{(item.gross/100.0).toFixed(2)}}
                            </h4>
                            <br/>
                            <b>Size:</b> {{item.size}}
                            <br/>
                            <b>Units per package:</b> {{item.units}}
                            <br/>
                            Availability: In Stock
                        </div>
                        <div class="description">
                            {{item.description}}
                        </div>
                        <div class="basket">
                            <div class="count-change-button" @click="changeQuantity(-1)"><i class="fas fa-minus"></i></div>
                            <input type="number" class="amount" v-model="count"/>
                            <div class="count-change-button" @click="changeQuantity(1)"><i class="fas fa-plus"></i></div>
                            <button class="hover-btn" v-bind:class="{'btn-disabled': count <= 0}" @click="addToBasket">ADD TO CART</button>
                            <span v-if="added === true" style="margin-left: 10px; color: green; line-height: 38px; font-size: 20px;"><i class="fas fa-check"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getProductPage} from "@/api/pages";
import EventBus from "@/api/event-bus";

export default {
    name: "Product",

    data() {
        return {
            item:false,
            count:1,
            selectedImage:false,
            added: false,
        }
    },
    created() {
    },
    mounted() {
        window.scrollTo(0, 0)
        this.loadItem();
    },
    methods: {
        loadItem: function() {
            console.log(this.$route);
            let productId = this.$route.params.id;

            let self = this;

            getProductPage(productId)
                .then(function(data) {
                    if(data.success === true) {

                        self.$data.item = data.item.product;
                        if(self.$data.item.mainImage !== null && self.$data.item.mainImage.length > 0) {
                            self.$data.selectedImage = self.$data.item.mainImage;
                        }
                        console.log(self.$data.item);
                    }
                })
        },
        addToBasket: function() {
            if(this.$data.count <= 0) {
                return;
            }

            this.$data.added = true;
            EventBus.$emit("addToBasket", this.$data.item, this.$data.count);
            this.$data.count = 1;
        },
        changeQuantity: function(diff) {

            console.log("change " + diff);
            this.$data.count += diff;
            if(this.$data.count <= 0) {
                this.$data.count = 1;
            }
        },
    }
}
</script>

<style scoped>
.product-image-container {
    width: 100%;
    padding-bottom: 30px;
    border-radius: 10px;
}
.product-image-container .image {
    width: 500px;
    max-width: 90%;
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding-bottom: 100%;
    border-radius: 10px;
}
.product-image-list-container {
    width: 100%;
    display: inline-block;
}
.product-image-list-container .image-item {
    float: left;
    width: calc(33.33% - 10px);
    margin: 5px;
    padding-bottom:calc(33.33% - 10px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}
.product-description-container {
    background: white;
    padding:20px;
    border-radius: 20px;
}
.product-description-container .info {
    border-top: 1px solid #ccc;
    padding-top:10px;
    padding-bottom: 10px;
}
.product-description-container .description {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
}
.product-description-container .basket {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
}

.product-description-container .basket .amount {
    height: 50px;
    width: 70px;
    text-align: center;
    /*-webkit-appearance: none;*/
    /*-moz-appearance: textfield;*/
}
.product-description-container .basket button {
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    background: #28ae9d;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 15px;
}

.hover-btn {
    transition: all 0.1s ease-in;
    box-shadow: 0 0 0 rgba(0,0,0,0.4);
}
.hover-btn:hover {
    transform: translate(-3px, -3px);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.4);
}
.product-description-container .basket button.btn-disabled {
    background: #ccc;
}
.count-change-button {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    background-color: #c4c4c4;
    cursor: pointer;
}
.count-change-button:hover {
    background-color: #666666;
    color: white;
}
.basket > * {
    float: left;
}
.basket input::-webkit-outer-spin-button,
.basket input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.basket input[type=number] {
    -moz-appearance:textfield; /* Firefox */
    border: 1px solid #c4c4c4;
}
</style>
