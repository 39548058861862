<template>
    <div style="width: 100%; padding-top: 20px; padding-bottom: 50px; background: #ddd;">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2>News</h2>
                </div>
            </div>
            <div class="row">
                <template v-for="item in items">
                    <div class="col-12 col-md-4 col-lg-4">
                        <div class="news-card-container">
                            <div class="image" v-bind:style="{backgroundImage: 'url('+ item.mainImage + ')'}">

                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="content">
                                {{item.shortDescription}}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewsListView",
    props:{
        items:{
            type:Array,
            default: function() {return [];}
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style scoped>
.news-card-container {
    background: white;
    width: calc(100% - 20px);
    margin: auto;
    margin-top:10px;
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 3px 5px gray;

}
.news-card-container .image {
    width: 100%;
    padding-bottom: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.news-card-container .title {
    font-weight: bold;
    padding: 5px;
    text-align: center;
    font-size: 1.5rem;
}
.news-card-container .content {
    padding: 5px 5px 20px 5px;
    text-align: center;

}
@media only screen and (max-width: 768px) {
    .news-card-container .image {
        padding-bottom: 60%;
    }
}
</style>
