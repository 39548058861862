<template>
    <div style="width: 100%;">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3>Kokoro Trading UK Privacy Policy</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Your Data</h5>
                    <p>We collect your email address, contact and shipping address only and never share or sell to any third parties.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "privacyPolicy",
    created() {
        window.scrollTo(0, 0)
    },
    mounted() {
    },
}
</script>

<style scoped>

</style>
