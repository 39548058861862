<template>
    <div style="width: 100%; background: white;">
        <div class="container" v-if="orderItem !== false">
            <div class="row">
                <div class="col-12 page-title">
                    Your Order #{{orderItem.id}}
                </div>
                <div class="col-12">
                    Ordered at {{timeToLocalText(orderItem.createdTime)}}
                    <span v-if="orderItem.authorisedTime > 0">
                        <br/> Paid at {{timeToLocalText(orderItem.authorisedTime)}}
                    </span>
                </div>
                <div class="col-12 col-md-6">
                    <div class="container order-container">
                        <div class="row">
                            <div class="col-12">
                                <template v-for="(item, ii) in orderItem.items">
                                    <div v-bind:key="'basket-item-'+item.productId" class="basket-item-container">
                                        <div class="basket-item-image" v-bind:style="{backgroundImage : 'url(' + item.image + ')'}">

                                        </div>
                                        <div class="info-container">
                                            <div class="title">
                                                {{item.title}} x {{item.quantity}}
                                            </div>
                                            <div class="price">
                                                £{{(item.net/100.0).toFixed(2)}}
                                            </div>
                                        </div>


                                    </div>
                                </template>
                            </div>
                            <div class="col-12 subtotal-container">
                                Subtotal ({{orderItem.quantity}} items): <span style="font-weight: bold;">£{{(orderItem.net/100.0).toFixed(2)}}</span>
                            </div>
                            <div class="col-12 subtotal-container">
                                VAT: <span style="font-weight: bold;">£{{(orderItem.vat/100.0).toFixed(2)}}</span>
                            </div>
                            <div class="col-12 sum-container">
                                Total: <span style="font-weight: bold;">£{{(orderItem.gross/100.0).toFixed(2)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="container order-container">
                        <div class="row">
                            <div class="col-12">
                                <b>Delivery To</b>
                            </div>
                            <div class="col-12">
                                {{orderItem.recipient}}
                                <br/>
                                {{orderItem.address}}
                                <br/>
                                {{orderItem.contact}}
                                <br/>
                                {{orderItem.email}}
                            </div>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {retrieveOrder} from "@/api/orders";
import {timeToLocaleDateShort} from "@/api/common";

export default {
    name: "Order",
    data() {
        return {
            orderItem: false,
        }
    },
    created() {
    },
    mounted() {
        this.loadOrder();
    },
    methods: {
        loadOrder: function() {
            if (this.$route.query.hasOwnProperty('id')) {
                let id = this.$route.query.id;

                let self = this;
                retrieveOrder(id)
                    .then(function(data) {
                        if(data.success === true) {
                            self.$data.orderItem = data.item;
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                    })
            }
        },
        timeToLocalText: function(time) {
            let date = new Date(time);
            return timeToLocaleDateShort(date);
        }
    }
}
</script>

<style scoped>
.basket-item-container {
    width: 100%;
}
.basket-item-image {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border:1px solid #e4e4e4;
}
.basket-item-container .info-container {
    width: calc(100% - 100px);
    padding-bottom: 3px;
}
.basket-item-container .info-container .title {
    width: 100%;

}
.basket-item-container .info-container .price {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}
.basket-item-container .controller {
    width: 100%;
    padding-left: 10px;
}
.basket-item-container {
    width: 100%;
    //width: calc(100% - 30px);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top:10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.basket-item-container .info-container {
    width: calc(100% - 100px);
    display: flex;
    flex-wrap: wrap;
}
.basket-item-container .info-container .title {
    width: calc(100% - 200px);
    height: 2.6em;
    line-height: 1.3em;
    font-size: 18px;
    word-break: normal;
    padding-left: 10px;

}
.basket-item-container .info-container .price {
    width: 200px;
    height: 2.6em;
    line-height: 1.3em;
    font-size: 22px;
    font-weight: bold;
    text-align: right;
}
.subtotal-container {
    line-height: 1.5em;
    font-size: 22px;
    text-align: right;
    //padding-right: 30px;
}
.sum-container {
    line-height: 2.0em;
    font-size: 2em;
    text-align: right;
    //padding-right: 30px;

}
.page-title {
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
}

.order-container {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
</style>
