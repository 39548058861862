<template>
    <div style="width: 100%">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="page-title">Categories</div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row" style="padding-bottom: 50px;">
                <div class="col-6 col-md-4 category-card-container"  v-for="c in categories" v-bind:key="'category-item-'+ c.id">
                    <div class="category-card card" @click="onCategoryClicked(c)">
                        <div class="card-header">

                            <div class="category-card-item-image" v-if="c.mainImage !== null && c.mainImage.length > 0" v-bind:style="{backgroundImage: 'url(' + c.mainImage + ')'}">

                            </div>
                        </div>
                        <div class="card-body category-title">
                            {{c.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getMetas} from "@/api/metas";

export default {
    name: "Categories",
    data() {
        return{
            categories:[],
        }
    },
    created() {
    },
    mounted() {
        this.loadCategoryList();
    },
    methods: {
        loadCategoryList: function() {
            let self = this;
            getMetas('category')
                .then(function(data) {
                    if(data.success === true) {
                        data.item.sort(function(a, b){
                            return a.pos - b.pos;
                        })
                        self.$data.categories = data.item;
                    } else {

                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        onCategoryClicked: function(c) {
            this.$router.push('/product-category/' + c.id);
        }
    }
}
</script>

<style scoped>

.page-title {
    line-height: 70px;
    font-size: 50px;
    margin-top: 20px;
    text-transform: uppercase;
}
.card-body.category-title {
    line-height: 60px;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
}
.category-card {
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 5px gray;
    transition: all 0.1s ease-in;
}
.category-card:hover {
    transform: translateY(-10px);
}
.category-card .card-header {
    padding: 0;
}
.category-card-item-image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: white;
}

.category-card-container {
    padding-bottom: 10px;
}
@media only screen and (max-width: 430px) {
    .section-title {
        font-size: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .card-body.category-title {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 5px;
    }
}
</style>
