<template>
    <div style="width: 100%; background-color: white;">
        <div style="width: 100%;" v-if="page !== false">
            <div class="container">
                <div class="row" v-if="page.meta !== null">
                    <div class="col-12 page-title text-center" >
                        {{page.meta.title}}
                    </div>
                    <div class="col-12" v-if="page.meta.description !== null" style="padding-top: 20px; padding-bottom: 20px;font-weight: bold;">
                        {{page.meta.description}}
                    </div>
                </div>
            </div>
            <div class="container" style="padding-bottom: 30px;">
                <div class="row">
                    <template v-for="p in page.products">
                    <div class="col-6 col-md-4 col-lg-3" v-if="p.active === true" v-bind:key="'product-item-view-' + p.id">
                        <div class="product-item-view" @click="moveToProductPage(p.id)">
                            <div class="product-item-image"  v-bind:style="{backgroundImage : 'url(' + p.mainImage + ')'}">

                                <div class="magnifier" @click.stop="openProductView(p)">
                                    <i class="fas fa-search"></i>
                                    <div class="bottom-bubble">
                                        Quick View
                                    </div>
                                </div>
                            </div>

                            <div class="product-title">

                                {{p.title}}
                            </div>
                            <div class="product-price">
                                £{{(p.gross/100.0).toFixed(2)}}
                            </div>
                            <div class="product-description">
                                {{p.size}}
                            </div>
                            <div class="quick-basket-container">
                                <div class="quick-basket">
                                    <span class="quick-basket-link" @click.stop="addToBasket(p)">ADD TO BASKET</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import {getCategoryPage} from "@/api/pages";
import EventBus from "@/api/event-bus";

export default {
    name: "ProductCategory",
    data() {
        return {
            page: false,
        }
    },
    created() {
    },
    mounted() {
        window.scrollTo(0, 0)
        this.loadPage();
    },
    watch: {
        '$route.params.category': {
            handler: function() {
                this.loadPage();
            },
            immediate: true,
        }
    },
    methods: {
        loadPage: function() {
            console.log(this.$route);
            let categoryId = this.$route.params.category;


            let self = this;
            getCategoryPage(categoryId)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.page = data.item;
                    }
                })
                .catch(function(err) {
                    console.log(err);
                })
        },
        moveToProductPage: function(productId) {
            this.$router.push('/shop/product/' + productId);
        },
        addToBasket: function(product) {

            EventBus.$emit("addToBasket", product, 1);
        },
        openProductView: function(product) {
            EventBus.$emit("openProduct", product);
        }
    }
}
</script>

<style scoped>
.product-item-view {
    width: 90%;
    margin: 10px auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    //box-shadow: 3px 5px 5px rgba(0,0,0, 0.4);
    transition: all 0.1s ease-in;
    cursor: pointer;
    border: 1px solid #ebebeb;
    position: relative;
}
.product-item-view:hover {
    border: 1px solid black;
    //transform: translateY(-10px);
}
.product-item-view .product-item-image {
    width: 100%;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

}
.product-item-view .product-title {
    padding: 5px 15px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: white;
    //min-height: 32px;

}
.product-item-view .product-price {
    padding: 5px 15px;
    text-align: center;
    min-height: 50px;

}
.product-item-view .product-description {
    background-color: white;
    text-align: center;
    padding-bottom: 10px;
    min-height: 34px;

}
.page-title {
    line-height: 70px;
    font-size: 50px;
    margin-top: 20px;
    text-transform: uppercase;
}
.kokoro-btn {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
}
.quick-basket-container {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
}
.quick-basket {
    position: absolute;
    background-color: white;
    height: 50px;
    top: 100%;
    opacity: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.product-item-view:hover .quick-basket {
    top: 0;
    opacity: 1;
    padding-top: 15px;
    text-align: center;
}

.quick-basket-link {
    margin:auto;
    font-weight: bold;
    border-bottom: 2px solid black;
}

.magnifier {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    line-height: 50px;
    text-align: center;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.product-item-view:hover .magnifier {
    opacity: 1;
}

.product-item-view:hover .magnifier:hover {
    background-color: #484848;
    color: white;
}
.bottom-bubble {
    position: absolute;
    //padding: 2px;
    width: 80px;
    height: 25px;
    line-height: 25px;
    top: 100%;
    left: 50%;
    display: block;
    background: white;
    color: black;
    font-size: 0.8rem;
    border: 1px solid black;
}
</style>
