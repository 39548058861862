<template>
    <div class="main-site-header">
        <div class="main_header_content" style="width: 100%; height: 80px; padding:1rem;">
            <div class="logo_wrapper">
                <a href="/" class="logo_link">
                    <span style="width: 48px;">
                        <img class="top-logo" src="https://storage.googleapis.com/kokoro-294123.appspot.com/KTL%20logo.jpg"/>
                    </span>
                </a>
            </div>
            <div class="menu_wrapper">
                <div class="main_menu_container">
                    <ul id="main_menu">
                        <div class="menu_item hover-underline-animation dropdown">
                            <router-link to="/categories">PRODUCTS</router-link>
                            <div class="dropdown-content" v-if="categories.length > 0">
                                <template v-for="c in categories">
                                    <a v-bind:key="'nav-category-item-' + c.id" @click="moveTo('/product-category/' + c.id)">{{c.title}}</a>
                                </template>
                            </div>
                        </div>
<!--                        <template v-for="c in categories">-->
<!--                            <li class="menu_item hover-underline-animation" v-bind:key="'top-nav-cat-' + c.id"><router-link :to="'/product-category/' + c.id">{{c.title}}</router-link></li>-->
<!--                        </template>-->
                        <li class="menu_item hover-underline-animation" style="display: none;"><router-link to="/business-types">Business type</router-link></li>
                        <li class="menu_item hover-underline-animation" style="display: none;">branding</li>
<!--                        <li class="menu_item hover-underline-animation"><router-link to="/contact">contact/samples</router-link></li>-->
                        <li class="menu_item hover-underline-animation" style="display: none;">brochure</li>
                        <li class="menu_item hover-underline-animation"><router-link to="/delivery">delivery</router-link></li>
                        <li class="menu_item hover-underline-animation"><router-link to="/aboutUs">about us</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="menu_button_wrapper" style="z-index: 1001; margin-left: 20px;">
                <a href="#" class="mobile_menu_button line_through" arial-label="Click to open mobile menu">
                    <div class="mobile-menu-container" v-bind:class="{'change': mobileMenuOpen === true}" @click="mobileMenuOpen = !mobileMenuOpen">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                    </div>
                </a>
            </div>
            <div class="search-container">
                <input v-model="searchWord" class="search-input" @change="searchProduct" placeholder="Search..."/>
                <button class="search-button" @click="searchProduct"><i class="fas fa-search"></i></button>
            </div>
            <div class="shopping_icons">
                <a href="/signIn" class="shopping_link sign_in_button" v-if="user === false"> Sign In </a>
                <a href="/myPage" class="shopping_link sign_in_button" v-else>My Page</a>
                <a href="/basket" class="shopping_link cart_button"> Cart <span v-if="basket !== false && basket.quantity > 0" class="items_in_cart">{{basket.quantity}}</span></a>
            </div>

        </div>
        <div class="sub_header_content" style="display: none;">
            <div class="sub_heading_section kokoro-background-color">free delivery on all orders over £130 ex VAT</div>
            <div class="sub_heading_section kokoro-background-color">CALL US ON XXXXX</div>
            <div class="sub_heading_section kokoro-background-color">SEARCH OUR SITE</div>
        </div>
        <div class="mobile-sub-header">
            <div class="search-container search-mobile">
                <input v-model="searchWord" class="search-input" @change="searchProduct" placeholder="Search..."/>
                <button class="search-button" @click="searchProduct"><i class="fas fa-search"></i></button>
            </div>
        </div>
        <transition name="slide-fade">
        <div class="mobile-nav-container" v-if="mobileMenuOpen === true">
            <ul class="list-group">
                <li class="list-group-item"><router-link to="/" @click.native="mobileMenuOpen = false">Home</router-link></li>
                <li class="list-group-item"><router-link to="/categories" @click.native="mobileMenuOpen = false" >PRODUCTS</router-link></li>
<!--                <template v-for="c in categories">-->
<!--                    <li class="list-group-item" v-bind:key="'top-nav-cat-mobile-' + c.id" ><router-link :to="'/product-category/' + c.id" @click.native="mobileMenuOpen = false"> - {{c.title}}</router-link></li>-->
<!--                </template>-->
                <li class="list-group-item" style="display: none;"><router-link to="/business-types" @click.native="mobileMenuOpen = false">Business type</router-link></li>
                <li class="list-group-item" style="display: none;">branding</li>
                <li class="list-group-item" style="display: none;">contact/samples</li>
                <li class="list-group-item" style="display: none;">brochure</li>
                <li class="list-group-item"><router-link to="/delivery" @click.native="mobileMenuOpen = false">delivery</router-link></li>
                <li class="list-group-item"><router-link to="/aboutUs" @click.native="mobileMenuOpen = false">about us</router-link></li>

            </ul>
        </div>
        </transition>

        <div v-if="dialogView !== false" style="position: fixed; top:0; left:0; width: 100%; height: 100%; z-index: 90; background-color: rgba(0,0,0,0.4);">
            <div class="inner-dialog">
                <div class="dialog-title">
                    {{dialogView.title}}
                </div>
                <div class="dialog-content">
                    {{dialogView.text}}
                </div>
                <div class="dialog-footer">
                    <div class="dialog-footer-btn" @click="dialogView = false">
                        Close
                    </div>
                </div>

            </div>

        </div>
        <Transition name="nested">
            <div class="outer" v-if="productView !== false" style="position:fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4)" @click="productView = false">
                <div class="inner inner-container" @click.stop="dummy">
                    <product-item-view
                        :item="productView"
                        :on-close="closeProduct"
                        :on-add-to-bakset="addToBasket"
                        :on-added="showQuickBasket">

                    </product-item-view>
                </div>
            </div>
        </Transition>
        <Transition name="nested">
            <div class="outer" v-if="quickBasketView !== false" style="position:fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4)" @click="quickBasketView = false">
                <div class="inner inner-container" @click.stop="dummy">
                    <quick-basket
                        v-if="quickBasketView !== false"
                        :item="quickBasketView.item"
                        :on-close="closeQuickBasket"
                        :basket="basket">

                    </quick-basket>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import {getFrontPage} from "@/api/pages";
import EventBus from "@/api/event-bus";
import {getUserByToken} from "@/api/users";
import ProductItemView from "@/components/ProductItemView.vue";
import QuickBasket from "@/components/QuickBasket.vue";
export default {
    name: "Header",
    data() {
        return {
            categories:[],
            businesses:[],
            basket:false,
            mobileMenuOpen: false,
            dialogView:false,
            user:false,
            productView: false,
            quickBasketView: false,
            searchWord:'',
        }
    },
    components: {
        ProductItemView,
        QuickBasket,
    },
    created() {
        EventBus.$on("addToBasket", this.addToBasket);
        EventBus.$on("showDialog", this.showDialog);
        EventBus.$on("logon", this.onLoggedIn);
        EventBus.$on("logout", this.onLoggedOut);
        EventBus.$on("openProduct", this.openProduct);
    },
    mounted() {
        this.onLoggedIn();
        this.initialise();
    },
    methods: {
        dummy: function() {

        },
        initialise: function() {
            let self = this;
            getFrontPage()
            .then(function(data) {
                if(data.success === true) {
                    console.log("Before sorting")
                    console.log(data.item.categories);
                    data.item.categories.sort(function(a, b){
                        return a.pos - b.pos;
                    })
                    self.$data.categories = data.item.categories;
                    console.log("After sorting")
                    console.log(data.item.categories);
                    self.$data.businesses = data.item.businesses;
                }
            })
            .catch(function(err) {
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }
            })

            if(localStorage.getItem("basket") !== null && localStorage.getItem("basket") !== false && localStorage.getItem("basket").length  > 0) {
                this.$data.basket = JSON.parse(localStorage.getItem("basket"));
            }
        },
        moveTo: function(dest) {
            this.$router.push(dest);
        },
        addToBasket: function(item, qty) {
            qty = parseInt(qty);
            console.log("add to basket received " + qty);
            let basket = localStorage.getItem("basket");
            if(basket === null || basket === false || basket.length === 0) {
                basket = {
                    qty:0,
                    net:0,
                    vat:0,
                    gross:0,
                    items:[],
                }
            } else {
                basket = JSON.parse(basket);
            }

            let found = false;
            for(let i = 0 ; i < basket.items.length ; i++) {
                if(basket.items[i].comProductId === item.id) {
                    basket.items[i].quantity += qty;
                    basket.items[i].net = item.net*qty;
                    basket.items[i].vat = item.vat*qty;
                    basket.items[i].gross = item.gross*qty;
                    found = true;
                    break;
                }
            }

            if(found === false) {
                let orderItem = {
                    id:0,
                    userId:0,
                    title: item.title,
                    productId:item.productId,
                    comProductId: item.id,
                    image:item.mainImage,
                    quantity:qty,
                    unitPrice:item.net,
                    vatRate:item.vatRate,
                    net:item.net*qty,
                    vat:item.vat*qty,
                    gross:item.gross*qty,
                }

                basket.items.push(orderItem);
            }

            basket.quantity = 0;
            basket.net = 0;
            basket.vat = 0;
            basket.gross = 0;
            for(let i = 0 ; i < basket.items.length ; i++) {
                basket.quantity+= basket.items[i].quantity;
                basket.net+= basket.items[i].net;
                basket.vat+= basket.items[i].vat;
                basket.gross+= basket.items[i].gross;

            }

            this.$data.basket = basket;
            localStorage.setItem("basket", JSON.stringify(basket));

            this.showQuickBasket(item, qty);

        },
        toggleMenu: function() {

        },
        showDialog: function(params) {
            console.log("Dialog View ");
            console.log(params);
            this.$data.dialogView = params;
        },
        onLoggedOut: function() {
            console.log("log out");
            this.$data.user = false;
            localStorage.removeItem("JSTOKEN");
            localStorage.removeItem("email");
            localStorage.removeItem("id");
            this.$router.push("/");

        },
        onLoggedIn: function() {

            if(localStorage.getItem("JSTOKEN") !== null && localStorage.getItem("JSTOKEN").length > 0) {


                let self = this;
                getUserByToken()
                    .then(function (data) {
                        console.log("user token");
                        console.log(data);
                        self.$data.user = data;

                    })
                    .catch(function (err) {

                        self.onLoggedOut();
                        console.log(err);
                        if (err.response !== undefined) {
                            console.log(err.response);
                        }
                    })
            }
        },
        openProduct: function(product) {
            this.$data.productView = JSON.parse(JSON.stringify(product));

        },
        closeProduct: function() {
            this.$data.productView = false;
        },
        showQuickBasket: function(item, qty) {
            item.quantity = qty;
            this.$data.quickBasketView = {
                item: item
            }
        },
        closeQuickBasket: function() {
            this.$data.quickBasketView = false;
        },
        searchProduct: function() {
            this.$router.push("/search?search=" + this.$data.searchWord);
        }

    }
}
</script>

<style scoped>
a {
    position: relative;
    text-decoration: none;
}
.main_header_content {
    height: 5.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: white;
}
.logo_wrapper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.menu_wrapper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.shopping_icons {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main_menu_container {
    height: 100%;

}
#main_menu {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
}
.menu_item {
    margin-left: 70px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
}

.cart_button {
    display: flex;
    align-items: center;
}
.shopping_icons .shopping_link {
    margin-left: 1.75rem;
}
.shopping_icons .shopping_link:first-of-type {
    margin-left: 0;
}
@media (max-width: 1366px) {
    .main_header_content {
        padding: 1rem;
    }
    .logo_wrapper {
        width: 10rem;
    }
    .shopping_icons {
        width: 9rem;
    }
}
@media only screen and (max-width: 430px) {
    .logo_wrapper {
        display: none;
    }
}
.hover-underline-animation {
    display: inline-block;
    position: relative;
    /*color: #0087ca;*/
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.cart_button .items_in_cart {
    display: block;
    position: absolute;
    top: -3px;
    right: -9px;
    color: white;
    font-size: 10px;
    width: 16px;
    height: 16px;
    background-color: #333334;
    border-radius: 50%;
    border: 1px solid white;
    text-align: center;
    line-height: 13px;
    transition: 0.2s ease-out;
}
.sub_header_content {
    transition: 0.2s ease-out;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    width: 100%;
    height: 40px;
    max-height: 40px;
    background-color: #f1e779;
}
.sub_header_content .sub_heading_section:first-of-type {
    border-left: none;
}
.sub_header_content .sub_heading_section {
    /*background-color: #f1e779;*/
    height: 40px;
    line-height: 40px;
    border-left: 1px solid white;
    width: 33.333333%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.main_header_content .menu_button_wrapper {
    display: none;
}
.main_header_content .menu_button_wrapper .mobile_menu_button {
    display: none;
    cursor: pointer;
    color: #333334;
    transition: 0.2s ease-out;
}
.top-logo {
    height: 60px;
    margin-left: 20px;

}
@media only screen and (max-width: 1100px) {
    .top-logo {
        height: 35px;
    }
    .main_header_content .menu_button_wrapper {
        display: block;
        position: relative;
    }
    .main_header_content .menu_button_wrapper .mobile_menu_button {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translateY(-50%) translateX(-50%);

    }

    .menu_wrapper {
        display: none;
    }
    .sub_header_content {
        height: 90px;
        min-height: 90px;
    }
    .sub_header_content .sub_heading_section {
        height: 30px;
        line-height: 30px;
        width: 100%;
        font-size: 0.8rem;
    }

}

.mobile_menu_button .mobile-menu-container {
    display: inline-block;
    cursor: pointer;

}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.mobile-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    height: 100%;
    background-color: white;
    z-index: 1000;
    padding-top: 100px;
}
.mobile-nav-container .list-group-item {
    text-transform: uppercase;
}
.slide-fade-enter-active {
    transition: all .2s ease-in;
}
.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100px);
    opacity: 0;
}
.inner-dialog {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    padding: 10px;
}
.inner-dialog .dialog-title {
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    font-size: 1.5rem;
}
.inner-dialog .dialog-content {
    text-align: center;
    padding:20px;
    text-align: center;
}
.dialog-footer .dialog-content {
    display: table;
    width: 100%;
    table-layout: fixed;
    text-align: center;
}
.dialog-footer .dialog-footer-btn{
    display: table-cell;
    text-align: center;
}


.dropbtn {
    //background-color: #04AA6D;
    //color: white;
    //padding: 16px;
    //font-size: 16px;
    //border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}


.main-site-header {
    border-bottom: 1px solid #c4c4c4;
}



.nested-enter-active, .nested-leave-active {
    transition: all 0.3s ease-in-out;
}
/* delay leave of parent element */

/*
.nested-leave-active {
  transition-delay: 0.25s;
}
*/
.nested-enter,
.nested-leave-to {
    opacity: 0 !important;;
}
.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: transform 0.3s ease-in-out;
}
/*
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
*/
.nested-enter .inner,
.nested-leave-to .inner {
    transform: translateY(50%);
//opacity: 0;
}


.nested-enter-active .inner-slide,
.nested-leave-active .inner-slide {
    transition: transform 0.3s ease-in-out;
}
/*
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
 */
.nested-enter .inner-slide,
.nested-leave-to .inner-slide {
    transform: translateX(100%);
//opacity: 0;
}

.inner-container{
    width: 1000px;
    max-width: 90%;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //bottom: 0;
    margin: auto;
    margin-top: 100px;
    background-color: white;
    //padding-top: 30px;
    //padding-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
}
.search-container {
    height: 48px;

    //padding-left: 24px;
    //border: 2px solid black;
    //border-radius: 24px;
}
.search-container .search-input {
    height: 48px;
    line-height: 48px;
    border: 2px solid black;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding-left: 10px;
}
.search-container .search-button {
    height: 48px;
    color: white;
    padding: 0 20px;
    background-color: black;
    //padding-right:24px;
    border-top-right-radius: 24px;;
    border-bottom-right-radius: 24px;
    border:none;
}

.mobile-sub-header {
    width: 100%;
    height: 50px;
    display: none;
    text-align: center;
    background-color: white;
}
.search-mobile {
    margin: auto;
}

@media only screen and (max-width: 860px) {
    .search-container {
        display: none;
    }
    .mobile-sub-header {
        display: block;
    }

    .search-mobile {
        display: block;
        width: 90%;
    }

    .search-input {
        padding-left: 10px;
        width: calc(100% - 60px);
    }
}
</style>
