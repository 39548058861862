<template>
    <div style="width: 100%;">
        <div style="width: 100%;" v-if="page !== false">
            <div class="container">
                <div class="row" v-if="page.meta !== null">
                    <div class="col-12" >
                        <h1>{{page.meta.title}}</h1>
                    </div>
                    <div class="col-12" v-if="page.meta.description !== null" style="padding-top: 20px; padding-bottom: 20px;font-weight: bold;">
                        {{page.meta.description}}
                    </div>
                </div>
            </div>
            <div class="container" style="padding-bottom: 30px;">
                <div class="row">
                    <div class="col-6 col-md-4" v-for="p in page.products" v-bind:key="'product-item-view-' + p.id">
                        <div class="product-item-view" @click="moveToProductPage(p.id)">
                            <div class="product-item-image" v-if="p.mainImage !== null && p.mainImage.length > 0" v-bind:style="{backgroundImage : 'url(' + p.mainImage + ')'}">

                            </div>

                            <div class="product-title">

                                £{{(p.gross/100.0).toFixed(2)}} | {{p.title}}
                            </div>
                            <div class="product-description">
                                {{p.size}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getBusinessPage} from "@/api/pages";

export default {
    name: "ProductBusinessType",
    data() {
        return {
            page: false,
        }
    },
    created() {
    },
    mounted() {
        this.loadPage();
    },
    methods: {
        loadPage: function() {
            console.log(this.$route);
            let typeId = this.$route.params.business;


            let self = this;
            getBusinessPage(typeId)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.page = data.item;
                    }
                })
                .catch(function(err) {
                    console.log(err);
                })
        },
        moveToProductPage: function(productId) {
            this.$router.push('/shop/product/' + productId);
        }
    }
}
</script>

<style scoped>
.product-item-view {
    width: 90%;
    margin: auto;
    box-shadow: 3px 5px 5px rgba(0,0,0, 0.4);
    transition: all 0.1s ease-in;
}
.product-item-view:hover {
    transform: translateY(-10px);
}
.product-item-view .product-item-image {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.product-item-view .product-title {
    padding: 1rem 20px 0 2px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: white;

}
.product-item-view .product-description {
    background-color: white;
    text-align: center;
    padding-bottom: 10px;
}
</style>
