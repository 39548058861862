
<template>

    <div style="width: 100%; height: 100%;">
<!--        <div class="quick-basket-view" @click.stop="dummy">-->
            <div class="header">
                Product Successfully Added To Your Shopping Cart
                <div style="width: 50px; height: 50px; text-align: center; position:absolute; top: 0; right: 0; font-size: 25px;" @click="closeView">
                    <i class="fas fa-times"></i>
                </div>
            </div>

            <div class="container" style="padding-top: 50px;">
                <div class="row">
                    <div class="col-5">

                        <div class="row">
                            <div class="col-6" style="padding-bottom: 30px;">
                                <div class="product-image" v-bind:style="{backgroundImage: 'url(' + item.mainImage +')'}">

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="product-info">
                                    <div class="product-title">
                                        {{item.title}}
                                    </div>

                                    <div class="product-price">
                                        £{{(item.gross/100.0).toFixed(2)}}
                                    </div>
                                    <div class="product-quantity">
                                        Quantity: {{item.quantity}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="basket-info-container">
                            There are {{basket.quantity}} items in your cart.
                            <br/>
                            <b>Total products:</b> £{{(basket.gross/100.0).toFixed(2)}}
                            <br/>
                            <b>Total </b> £{{(basket.gross/100.0).toFixed(2)}}
                        </div>

                        <div class="basket-button-container">
                            <div class="basket-button" @click="continueShopping">Continue Shopping</div>
                            <div class="basket-button" @click="moveToBasket">Proceed to Checkout</div>
                        </div>
                    </div>
                </div>
            </div>

<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'QuickBasket',
    props:{
        item:{
            type:Object,
            default: function(){return null;}
        },
        basket:{
            type:Object,
            default: function() {return null;}
        },
        onClose: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {

        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        initialise: function() {

        },
        dummy: function() {

        },
        continueShopping: function() {
            if(this.onClose !== null) {
                this.onClose();
            }
        },
        moveToBasket: function() {
            this.$router.push('/basket');
            if(this.onClose !== null) {
                this.onClose();
            }
        },
        closeView: function() {
            if(this.onClose !== null) {
                this.onClose();
            }
        }
    }
}
</script>

<style scoped>
.quick-basket-view {
    //position: absolute;
    width: 1200px;
    max-width: 90%;
    margin: 100px auto;
    top: 100px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
}
.header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #28ae9d;
    color:white;
    font-weight: 600;
    text-align: center;
    position: relative;
}

.product-image {
    width: 100%;
    padding-bottom: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.product-title {
    font-weight: 600;
    margin-bottom: 30px;
}

.product-price {

}
.product-quantity {

}
.basket-button-container {
    width: 100%;
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;

}
.basket-button-container .basket-button {
    float: left;
    background-color: #28ae9d;
    color:white;
    margin-right: 20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0 0 0 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.basket-button:hover {
    transform: translate(-5px, -5px);
    box-shadow: 3px 3px 5px gray;
}

</style>
