<template>
    <div style="width: 100%; padding-top: 20px;">

        <template v-if="basket !== false">
            <div class="container  input-container" >

                <div class="row" v-if="basket !== false">
                    <div class="col-12" style="border-bottom: 1px solid #ddd; padding-bottom: 10px;">
                        <h4>Your Basket</h4>
                    </div>
                    <div class="col-12" v-if="basket.items.length > 0">
                        <template v-for="(item, ii) in basket.items">
                            <div v-bind:key="'basket-item-'+item.productId" class="basket-item-container">
                                <div class="basket-item-image" v-bind:style="{backgroundImage : 'url(' + item.image + ')'}">

                                </div>
                                <div class="info-container">
                                    <div class="title">
                                        {{item.title}}
                                    </div>
                                    <div class="price">
                                        £{{(item.net/100.0).toFixed(2)}}
                                    </div>
                                    <div class="controller">
                                        <div class="quantity-btn left-button" @click="changeQuantity(ii, -1)"><i class="fas fa-minus"></i></div>
                                        <input class="quantity-input" v-model="item.quantity"/>
                                        <div class="quantity-btn right-button" @click="changeQuantity(ii, 1)"><i class="fas fa-plus"></i></div>

                                        <div class="delete-btn" @click="deleteItem(ii)">Delete</div>
                                    </div>
                                </div>


                            </div>
                        </template>
                    </div>
                    <div class="col-12 subtotal-container">
                        Subtotal ({{basket.quantity}} items): <span>£{{(basket.net/100.0).toFixed(2)}}</span>
                    </div>
                    <div class="col-12 subtotal-container">
                        VAT: <span>£{{(basket.vat/100.0).toFixed(2)}}</span>
                    </div>
                    <div class="col-12 sum-container">
                        Total: <span style="font-weight: bold;">£{{(basket.gross/100.0).toFixed(2)}}</span>
                    </div>
                </div>


            </div>
            <hr/>
            <div class="container input-container">
                <div class="row delivery-form">
                    <div class="col-12">
                        <h4>Shipping Information</h4>
                    </div>
                    <div class="col-12">
                        <span class="delivery-form-title"> Receiver</span>
                        <input v-model="deliveryInfo.recipient"/>
                    </div>
                    <div class="col-12">
                        <span class="delivery-form-title"> Contact</span>
                        <input v-model="deliveryInfo.contact"/>
                    </div>
                    <div class="col-12">
                        <span class="delivery-form-title"> Email</span>
                        <input v-model="deliveryInfo.email"/>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-12">
                        <span style="font-weight: bold;">Shipping Address</span>
                    </div>
                    <div class="col-12" style="margin-top: 10px;">
                        <input type="text" class="text-center delivery-form-postcode" v-model="postCodeSearchWord" placeholder="POST CODE"/>
                        <button class="btn btn-success kokoro-background-color" style="margin-left: 10px;" v-on:click="getAddressCandidates"><i class="fas fa-search "></i> Find Address</button>
                    </div>
                </div>
<!--                <div class="row" v-if="selectedCandidate != null && selectedCandidate.length > 0" style="">-->
<!--                    <div class="col-12 text-left" style="padding-top:10px;">-->
<!--                        <div style="border: 1px solid gray; padding:10px; border-radius: 5px; background-color: #D1E9FF;">-->
<!--                            {{selectedCandidate}}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-12 text-left" style="padding-top:10px; padding-bottom:10px;" v-if="addressCandidates.length > 0">-->
<!--                        <button class="btn btn-dark" v-on:click="processAddress">Use This Address</button>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="row" v-if="addressCandidates !== null && addressCandidates.length > 0">
                    <div class="col-12">
                        Address
                        <select v-model="deliveryInfo.address" class="form-control">
                            <option value="">Select address</option>
                            <template v-for="(can, ci) in addressCandidates">
                                <option v-bind:key="'address-candidate-option-' + ci" :value="can.summaryline">{{can.summaryline}}</option>
                            </template>
                        </select>
                    </div>
<!--                    <template v-for="(can, ci) in addressCandidates">-->
<!--                    <div class="col-12 text-left" v-bind:key="'candidate-address-' + ci"  v-on:click="selectCandidate(can.summaryline)">-->
<!--                        <div class=" address-candidate">-->
<!--                            {{can.summaryline}}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    </template>-->
                </div>

                <div class="row">

                    <div class="col-12 text-right">
                        <button v-if="validateRecipient() === true" class="btn btn-success kokoro-background-color big-btn" @click="submitOrder">Proceed to Payment</button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import {createStripeSession, getAddressListByPostCode, submitOrder} from "@/api/orders";
import {getUserByToken} from "@/api/users";
export default {
    name: "Basket",
    data() {
        return {
            basket:false,
            deliveryInfo:{
                recipient:'',
                postCode:'',
                address:'',
                contact:'',
                email:'',
            },
            postCodeSearchWord:'',
            addressCandidates:[],
            selectedCandidate:'',
            stripe:null,
            user:false,

        }
    },
    created() {
    },
    mounted() {
        this.loadBasket();
    },
    methods: {

        loadBasket: function() {
            if(localStorage.getItem("basket") !== null && localStorage.getItem("basket") !== false && localStorage.getItem("basket").length > 0) {
                this.$data.basket = JSON.parse(localStorage.getItem("basket"));
                console.log("basket loaded");

                let self = this;
                getUserByToken()
                    .then(function(data) {
                        self.$data.user = data;
                        self.$data.deliveryInfo.recipient = self.$data.user.name;
                        self.$data.deliveryInfo.email = self.$data.user.email;
                    });
            }

        },
        getAddressCandidates: function() {
            if(this.$data.postCodeSearchWord == '') {
                // this.showPopup("", "Please Enter Post code");
                return;
            }
            let self = this;
            self.$data.addressCandidates = [];


            getAddressListByPostCode(this.$data.postCodeSearchWord)
                .then(function(response) {
                    if(response.status == 200) {
                        if(response.data.addresses != undefined && response.data.addresses.length > 0) {
                            // console.log("Addresses found");
                            let postCode = self.$data.postCodeSearchWord;
                            if(response.data.postcode != undefined && response.data.postcode != null && response.data.postcode != '' && response.data.postcode.length > 0) {
                                postCode = response.data.postcode;
                            }
                            let list = response.data.addresses;
                            for(let i = 0; i < list.length ; i++) {
                                // console.log("Address process " + i);
                                let obj = list[i];
                                if(obj.formatted_address != undefined && obj.formatted_address.length > 0) {

                                    // console.log("Found formatted_address ");
                                    // console.log(obj.formatted_address);
                                    let summaryLines = [];
                                    for(let j = 0 ; j < obj.formatted_address.length ; j++) {
                                        if(obj.formatted_address[j] !== '') {
                                            console.log("Now add " + obj.formatted_address[j]);
                                            summaryLines.push(obj.formatted_address[j]);
                                        }

                                    }


                                    if(summaryLines.length > 0) {

                                        summaryLines.push(postCode);
                                        let candidate = {
                                            summaryline: summaryLines.join(', '),
                                        }

                                        self.$data.addressCandidates.push(candidate);
                                    } else {
                                        // console.log("no summary line");
                                    }
                                } else {
                                    // console.log("formatted_address not found")
                                }
                            }
                        }
                        // self.$data.addressCandidates = response.data;
                        if(self.$data.addressCandidates.length == 0) {
                            // self.showPopup("", "Sorry no address found");
                        }
                    } else {
                        console.log("Error");
                    }
                })
                .catch(function(err) {
                    console.log(err);
                })

        },
        selectCandidate: function(address) {
            this.$data.selectedCandidate = address;

            // this.onPostCodeEntered();
        },
        processAddress: function() {
            this.$data.deliveryInfo.address = this.$data.selectedCandidate;
            // this.$data.selectedCandidate = '';
            this.$data.addressCandidates = [];
        },
        changeQuantity: function(index, diff) {
            this.$data.basket.items[index].quantity += diff;
            if(this.$data.basket.items[index].quantity <= 0) {
                this.deleteItem(index);
            }
            this.updateOrder();
        },
        deleteItem: function(index) {
            this.$data.basket.items.splice(index, 1);
            this.updateOrder();
        },
        updateOrder: function() {
          this.$data.basket.quantity = 0;
          this.$data.basket.net = 0;
          this.$data.basket.vat = 0;
          this.$data.basket.gross = 0;
          for(let i = 0 ; i < this.$data.basket.items.length ; i++) {
              this.$data.basket.quantity += this.$data.basket.items[i].quantity;
              this.$data.basket.items[i].net = this.$data.basket.items[i].unitPrice * this.$data.basket.items[i].quantity;
              this.$data.basket.items[i].vat = Math.round(this.$data.basket.items[i].net * this.$data.basket.items[i].vatRate/10000);
              this.$data.basket.items[i].gross = this.$data.basket.items[i].net + this.$data.basket.items[i].vat;

              this.$data.basket.net += this.$data.basket.items[i].net;
              this.$data.basket.vat += this.$data.basket.items[i].vat;
              this.$data.basket.gross += this.$data.basket.items[i].gross;
          }

          localStorage.setItem('basket', JSON.stringify(this.$data.basket));
        },
        submitOrder: function() {

            let order = this.$data.basket;
            order.recipient = this.$data.deliveryInfo.recipient;
            order.postCode = this.$data.deliveryInfo.postCode;
            order.address = this.$data.deliveryInfo.address;
            order.contact = this.$data.deliveryInfo.contact;
            order.email = this.$data.deliveryInfo.email;
            order.state = -1;


            let self = this;
            submitOrder(order)
                .then(function(data) {
                    if(data.success === true) {
                        localStorage.removeItem('basket');
                        self.$data.basket = false;
                        self.startStripePayment(data.item);
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                })
        },
        validateRecipient: function() {


            if(this.$data.deliveryInfo.address === null || this.$data.deliveryInfo.address.length < 1) {
                return false;
            }
            if(this.$data.deliveryInfo.email === null || this.$data.deliveryInfo.email.length < 1) {
                return false;
            }
            if(this.$data.deliveryInfo.recipient === null || this.$data.deliveryInfo.recipient.length < 1) {
                return false;
            }
            if(this.$data.deliveryInfo.contact === null || this.$data.deliveryInfo.contact.length < 1) {
                return false;
            }

            return true;


        },
        startStripePayment: function(order) {

            let self = this;
            createStripeSession(order)
                .then(function(data) {
                    if(data.success === true) {
                        if (data.item.stripePublicKey !== undefined && data.item.stripePublicKey !== null && data.item.stripePublicKey.length > 0) {

                            // if (data.item.stripeClientId !== undefined && data.item.stripeClientId !== null && data.item.stripeClientId.length > 0) {
                            //     self.$data.stripe = Stripe(data.item.stripePublicKey, {
                            //         stripeAccount: data.item.stripeClientId
                            //     });
                            //
                            // } else {
                            //
                            // }
                            self.$data.stripe = Stripe(data.item.stripePublicKey);
                            return self.$data.stripe.redirectToCheckout({sessionId: data.item.pgToken});

                        } else {

                            console.log("Failed");
                            console.log(data);
                        }
                    }
                })
                .catch(function(err) {
                    console.log(err);
                })
        }
    }
}
</script>

<style scoped>
.input-container {
    background: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 3px 3px 5px gray;
}
.input-container .row {
    margin-top: 10px;
}
.basket-table td {
    line-height: 38px;
}
.basket-table .summary th {
    font-size: 1.2rem;
}
.delivery-form > div {
    display: flex;
}
.delivery-form-title {
    width: 100px;
    min-width: 10px;
}
.delivery-form input {
    width: 300px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}
.delivery-form-postcode {
    width: 200px;
    height: 40px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;

}
.address-candidate {
    line-height: 30px;
    padding-left: 10px;
    border: 1px solid gray;
}
.delivery-form .col-12 {
    margin-top:10px;
}
.basket-item-container {
    width: calc(100% - 30px);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top:10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.basket-item-container .info-container {
    width: calc(100% - 180px);
    display: flex;
    flex-wrap: wrap;
}
.basket-item-container .info-container .title {
    width: calc(100% - 200px);
    height: 2.6em;
    line-height: 1.3em;
    font-size: 18px;
    word-break: normal;
    padding-left: 10px;

}
.basket-item-container .info-container .price {
    width: 200px;
    height: 2.6em;
    line-height: 1.3em;
    font-size: 1.1rem;
    //font-weight: bold;
    text-align: right;
}
.basket-item-container .info-container .controller {
    width: 100%;
    display: flex;
    padding-left: 10px;

}
.basket-item-container .info-container .controller .quantity-btn{
    width: 50px;
    height: 38px;
    line-height: 38px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #eee;
    border:1px solid #ccc;

    cursor: pointer;
    text-align: center;
}
.left-button {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
}
.right-button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
}
.basket-item-container .info-container .controller .quantity-input {
    width: 50px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid #ccc;
    color: #666;
}
.basket-item-container .info-container .controller .delete-btn {
    height: 38px;
    line-height: 38px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #eee;
    border:1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 20px;
    text-align: center;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
    cursor: pointer;
}
.basket-item-quantity {
    height: 40px;
    line-height: 40px;
    width: 70px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.basket-item-image {
    width: 180px;
    height: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.subtotal-container {
    line-height: 1.2em;
    font-size: 22px;
    text-align: right;
    padding-right: 30px;
    padding-top: 20px;
}
.sum-container {
    line-height: 2.0em;
    font-size: 1.5em;
    text-align: right;
    padding-right: 30px;

}
.hide-mobile {

}
.show-mobile {
    display: none;
}
.big-btn {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}
@media only screen and (max-width: 1100px) {
    .hide-mobile {
        display: none;
    }
    .show-mobile {
        display: block;
    }
    .input-container {
        width: calc(100% - 20px);

    }
    .basket-item-container {
        width: 100%;
    }
    .basket-item-container .basket-item-image {
        width: 100px;
        height: 100px;
    }
    .basket-item-container .info-container {
        width: calc(100% - 100px);
        padding-bottom: 10px;
    }
    .basket-item-container .info-container .title {
        width: 100%;

    }
    .basket-item-container .info-container .price {
        width: 100%;
        text-align: left;
        padding-left: 10px;
    }
    .delivery-form > div {
        padding-top:5px;
        padding-bottom: 5px;
    }
    .delivery-form-postcode {
        width: 150px;
        margin-left: 5px;
        margin-top: 5px;
        height: 40px;

    }

}
</style>
